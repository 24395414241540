<template>
  <v-container fluid>
    <page-header title='Avaliações do curso'></page-header>
    <v-card style="border-radius:12px">
        <v-card-title primary-title class='primary white--text py-1'>
          <v-row class="px-3 py-4 pb-5" justify="space-between">
            <v-col cols='12' md='9' class="pa-0">
              <div class="text-subtitle-2">Curso</div>
              <div style="word-break: break-word">{{course.name}}</div>
            </v-col>
            <v-col cols='12' md='3' align="right">
              <v-btn color="success" class="mx-2" small rounded v-if="['done'].includes(course.status)" 
                :to="({name: 'CourseRatings', params: {id: course.id}})"
              >
                Ver avaliações do curso
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="px-4 pb-3 pt-2">
          <v-row class="d-flex justify-center">
            <v-col v-for="item in rating_items" :key="item.title" cols="12" lg="3" md="6" sm="12">
              <v-card style="border-radius:12px">
                <v-card-text style="height: 380px">
                  <card-loading-transparent v-if="loading" :cardWidth="10" :cardSize="120" class="mt-15"></card-loading-transparent>
                  <pie :item="item" v-if="(item.kind == 20 || item.kind == 50) && !loading"></pie>
                  <bar :item="item" v-if="item.kind == 40 && !loading"></bar>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'CourseRatingsDashboard',
  components: {
    Pie: () => import ('./graphics/PieGraphic.vue'),
    Bar: () => import ('./graphics/BarGraphic.vue'),
  },
  data: () => ({
    course: {},
    loading: true,
    rating_items: []
  }),
  methods: {
    async setData(){
      this.loading = true
      await this.getCourseInfo().then(()=>{
        this.getRatingInfos(this.course.id)
      })
    },
    async getCourseInfo(){
      return await Api.Course.show(this.$route.params.id).then(r=>r.data).then(d=> {
        let {id, name, status} = d
        this.course = {id, name, status}
      })
    },
    getRatingInfos(id){
      Api.RatingDashboard.index({course_id: id}).then(r=>r.data).then((d)=> {
        this.rating_items = d
        setTimeout(()=>{
          this.loading = false
        },1200)
      })
    },
    ...mapActions({
      // function: 'Module/action'
    })
  },
  computed: {
    ...mapState({
      // data: state=> state.Module.state_var
    }),
    
  },
  mounted () {
    this.setData()
  },
}
</script>

<style scoped>

</style>